<template>
    <v-autocomplete
      label="Team Lookup"
      v-model="selectedResult"
      :hint="searchHint"
      placeholder="Start typing to search"
      persistent-hint
      color="color3"
      append-icon="fas fa-search"
      autocomplete="off"
      :items="items"
      item-value="id"
      item-text="fullName"
      return-object
      :search-input.sync="term"
      :loading="searching"
      :filter="filter"
      solo
      autofocus
      clearable
      type="search"
      ref="input"
      :disabled="disabled"
    >
      <template slot="no-data" v-if="allowAdd">
        <v-list-item @click.stop="$emit('manual-click', term)">
          <v-list-item-content>
            <v-list-item-title>
              Can't find the player?
            </v-list-item-title>
            <v-list-item-subtitle>Click here to manually enter the player</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-slot:item="data">
        <search-result-item
          :team="data.item"
          :showId="user && user.vblx"
        ></search-result-item>
      </template>
    </v-autocomplete>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'
import SearchResultItem from './SearchResultItem'

export default {
  props: ['type', 'disabled', 'allowAdd', 'limitToClubs'],
  data () {
    return {
      selectedResult: null,
      searchResults: [],
      term: null,
      searching: false,
      dupDialog: false,
      prevTerm: null,
      searched: false,
      newResults: true
    }
  },
  computed: {
    ...mapGetters(['liveUpdates', 'user']),
    searchHint () {
      return 'Enter the name of your team'
    },
    items () {
      return this.limitToClubs || (this.term && this.term.length >= 3 ? this.searchResults : [])
    }
  },
  methods: {
    search: debounce(function () {
      this.doSearch()
    }, 333),
    doSearch () {
      if (this.limitToClubs || !this.term || typeof this.term !== 'string' || this.term.length < 3 || (this.selectedResult && this.selectedResult.name === this.term)) return
      this.searching = true
      this.$VBL.roster.teams(this.type, this.term)
        .then(r => { this.searchResults = r.data })
        .catch(e => console.log(e))
        .finally(() => {
          this.searching = false
          this.searched = true
        })
    },
    filter () {
      return true
    },
    focus () {
      this.$refs.input.$el.focus()
    }
  },
  watch: {
    term: 'search',
    selectedResult: function (val) {
      if (val) {
        this.$emit('team-selected', val)
        if (this.clearOnSelect) {
          this.term = null
        }
      }
    }
  },
  components: {
    SearchResultItem
  }
}
</script>

<style>

</style>
